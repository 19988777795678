/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../containers/images/startMyExam.png';
import { useStyle } from '../styles/style';
import NavBar from './BaseComponents/NavBar';
import NavBarCompressed from './BaseComponents/NavBarCompressed';
import Search from './BaseComponents/Search';
const Header = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const width = window.innerWidth;
    return (React.createElement("div", { className: classes.header },
        React.createElement("img", { src: Logo, alt: "logo", className: classes.logo, onClick: () => {
                navigate('/', { replace: true });
            } }),
        React.createElement(Search, null),
        React.createElement(NavBar, null),
        React.createElement(NavBarCompressed, null)));
};
export default Header;
