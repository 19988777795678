/* eslint-disable react-hooks/rules-of-hooks */
import { Button, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import HTMLString from 'react-html-string';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from '../../UIElements/Icons/Loader';
import { loginModalOpen, postCheckCouponCode, postCheckCouponCodeSuccess, setSnackbar, } from '../../store/actions';
import { PRIMARY_COLOR } from '../../styles/colors';
import { useStyle } from '../../styles/style';
import { SliderImages } from '../LandingPage/SliderImages';
import CouponLoader from './../BaseComponents/Loader';
import { calculateDiscountPrice } from '../../utils/commonUtils';
const ExamLanding = () => {
    var _a;
    const navigate = useNavigate();
    const { examID } = useParams();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    if (!examID)
        throw new Error('Exam Id not found');
    const classes = useStyle();
    const { user } = useSelector((state) => state.auth);
    const { examLoader } = useSelector((state) => state.exam.loader);
    const { loader, coupon } = useSelector((state) => state.exam);
    const [couponCode, setCoupon] = useState('');
    const [price, setPrice] = useState({ amount: 0, amountUSD: 0 });
    const exams = useSelector((state) => state.exam.exams);
    const examSet = (_a = useSelector((state) => state.exam.examSet)) === null || _a === void 0 ? void 0 : _a[examID];
    const studentExams = useSelector((state) => state.auth.studentExams);
    const exam = exams[examID];
    const isExamBuyed = studentExams.filter((e) => e.exam.examID === examID);
    useEffect(() => {
        if (searchParams) {
            if (searchParams.get('status') === 'SUCCESS') {
                dispatch(setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'success',
                    snackbarMsg: 'Exam Purchased Successfully.',
                }));
            }
            else if (searchParams.get('status') === 'FAILURE') {
                dispatch(setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMsg: 'Error while purchasing exam.Please try agin.',
                }));
            }
        }
    }, []);
    useEffect(() => {
        if (!couponCode.length)
            dispatch(postCheckCouponCodeSuccess({ isValid: false }));
    }, [couponCode]);
    useEffect(() => {
        setPrice(calculateDiscountPrice(exam === null || exam === void 0 ? void 0 : exam.totalExamPrice, exam === null || exam === void 0 ? void 0 : exam.examPriceUSD, coupon));
    }, [coupon.isValid]);
    const handleApplyClick = () => {
        dispatch(postCheckCouponCode({ couponCode }));
    };
    const handleBuyClick = () => {
        var _a;
        if (isExamBuyed.length)
            dispatch(setSnackbar({
                snackbarOpen: true,
                snackbarType: 'warning',
                snackbarMsg: 'Exam Already Purchased.',
            }));
        else if (user.loggedIn)
            navigate('/payment', {
                state: {
                    examID,
                    examName: exam.examName,
                    studentID: (_a = user.userID) !== null && _a !== void 0 ? _a : user.studentId,
                    tenantID: exam.tenantID,
                    amount: exam.totalExamPrice,
                    amountUSD: exam.examPriceUSD,
                },
            });
        else
            dispatch(loginModalOpen());
    };
    return (React.createElement("div", { className: classes.examLandingMain }, examLoader ? (React.createElement(Loader, { loaderClass: classes.loader, divClass: classes.examLandingLoaderDiv })) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.examLandingSidePanel },
            React.createElement("div", null,
                React.createElement("img", { alt: "img", src: SliderImages[4], className: classes.examLandingImage })),
            React.createElement("div", { className: classes.examLandingSidePanelMain },
                React.createElement("div", null,
                    React.createElement(Typography, { style: { color: PRIMARY_COLOR.GREY150 } },
                        "No. of Sets: ",
                        exam.noOfSets),
                    React.createElement(Typography, { style: { color: PRIMARY_COLOR.GREY150 } },
                        "Duration of each set: ",
                        exam.durationInMinute,
                        " min"),
                    React.createElement(Typography, { style: { color: PRIMARY_COLOR.GREY150 } },
                        "No. of Question in each set: ",
                        exam.noOfQuestionsPerSet)),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { style: {
                            borderRight: `1px solid ${PRIMARY_COLOR.GREY40}`,
                        } },
                        React.createElement("div", { className: clsx(classes.examLandingPrice, (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) ? classes.diagonalCross : '') },
                            "\u20B9 ",
                            exam.totalExamPrice),
                        (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) && (React.createElement("div", { className: classes.examLandingPrice },
                            "\u20B9 ",
                            price.amount)),
                        ' ',
                        React.createElement(Typography, { variant: "caption" }, "(Only For Indian Student)")),
                    React.createElement("div", { style: { margin: '0 0 0 0.5rem' } },
                        React.createElement("div", { className: clsx(classes.examLandingPrice, (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) ? classes.diagonalCross : '') },
                            "$ ",
                            exam.examPriceUSD),
                        (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) && (React.createElement("div", { className: classes.examLandingPrice },
                            "\u20B9 ",
                            price.amountUSD)),
                        React.createElement(Typography, { variant: "caption" }, "(Students other than Indians)"))),
                React.createElement(Typography, null, "Have a Coupon Code?"),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement(TextField, { value: couponCode, onChange: (event) => {
                            setCoupon(event.target.value);
                        }, size: "small" }),
                    !loader.couponLoader ? (React.createElement(Button, { onClick: handleApplyClick, disabled: !couponCode.length }, "Apply Now")) : (React.createElement(CouponLoader, null))),
                React.createElement(Button, { variant: "contained", disableElevation: true, fullWidth: true, size: "large", onClick: handleBuyClick }, isExamBuyed.length ? 'PURCHASED' : 'BUY NOW'))),
        React.createElement("div", { className: classes.examLandingTopContainer },
            React.createElement("div", { className: classes.examLandingTopInner },
                React.createElement(Typography, { variant: "h1", className: classes.examLandingTitle }, exam.examName),
                React.createElement("div", { className: classes.examLandingDetails },
                    React.createElement("div", { style: { display: 'flex', alignItems: 'baseline' } },
                        React.createElement(Typography, null, "No. of Set: "),
                        React.createElement(Typography, null, "\u00A0"),
                        React.createElement(Typography, { variant: "h6" }, exam.noOfSets)),
                    React.createElement("div", { style: { display: 'flex', alignItems: 'baseline' } },
                        React.createElement(Typography, null, "Duration of each set: "),
                        ' ',
                        React.createElement(Typography, null, "\u00A0"),
                        React.createElement(Typography, { variant: "h6" },
                            exam.durationInMinute,
                            " min")),
                    React.createElement("div", { style: { display: 'flex', alignItems: 'baseline' } },
                        React.createElement(Typography, null, "No. of Question in each set: "),
                        React.createElement(Typography, null, "\u00A0"),
                        React.createElement(Typography, { variant: "h6" }, exam.noOfQuestionsPerSet)),
                    React.createElement("div", { style: { display: 'flex', alignItems: 'baseline' } },
                        React.createElement(Typography, null, "Teacher: "),
                        React.createElement(Typography, null, "\u00A0"),
                        React.createElement(Typography, { variant: "h6" }, exam.teacherName))))),
        React.createElement("div", { className: classes.examLandingBody },
            React.createElement(Button, { variant: "contained", disableElevation: true, fullWidth: true, size: "large", onClick: handleBuyClick, className: classes.buyButton }, isExamBuyed.length ? 'PURCHASED' : 'BUY NOW'),
            React.createElement("div", { className: classes.examLandingBodyTnc },
                React.createElement(Typography, { variant: "h2" }, "Description"),
                React.createElement(HTMLString, { html: exam.examDescription })),
            React.createElement("div", { className: classes.examLandingBodyTnc },
                React.createElement(Typography, { variant: "h2" }, "Total Duration of each Examset"),
                React.createElement(Typography, { variant: "h3", sx: { marginLeft: '10px' } },
                    exam.durationInMinute,
                    " Minutes")),
            React.createElement("div", { className: classes.examLandingSetBox },
                React.createElement(Typography, { variant: "h2", style: { padding: '1rem' } }, "Sets"), examSet === null || examSet === void 0 ? void 0 :
                examSet.map((set, index) => (React.createElement("div", { key: set.examSetID, className: classes.examLandingSets },
                    React.createElement(Typography, { variant: "h3" },
                        index + 1,
                        ". ",
                        set.examSetName),
                    React.createElement(Typography, null,
                        "No of Questions: ",
                        exam.noOfQuestionsPerSet))))))))));
};
export default ExamLanding;
