import { FORGOT_PASSWORD_CLOSE, FORGOT_PASSWORD_OPEN, GET_STUDENT_EXAM_RESULT_ANALYSIS, GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE, GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS, GET_STUDENT_TAKEN_EXAM, GET_STUDENT_TAKEN_EXAM_SUCCESS, LOGIN_MODAL_CLOSE, LOGIN_MODAL_OPEN, POST_STUDENT_FORGOT_PASSWORD, POST_STUDENT_FORGOT_PASSWORD_VERIFY, POST_STUDENT_LOGIN, POST_STUDENT_LOGIN_FAILURE, POST_STUDENT_LOGIN_SUCCESS, POST_STUDENT_PASSWORD_RESET, POST_STUDENT_PASSWORD_RESET_SUCCESS, POST_STUDENT_SIGN_UP, POST_STUDENT_SIGN_UP_FAILURE, POST_STUDENT_SIGN_UP_SUCCESS, POST_TEACHER_LOGIN, POST_TEACHER_LOGIN_FAILURE, POST_TEACHER_LOGIN_SUCCESS, POST_TEACHER_PASSWORD_RESET, POST_TEACHER_PASSWORD_RESET_SUCCESS, POST_TEACHER_SIGN_UP, POST_TEACHER_SIGN_UP_SUCCESS, POST_TENANT_FORGOT_PASSWORD, POST_TENANT_FORGOT_PASSWORD_VERIFY, RESET_PASSWORD_MODAL_CLOSE, RESET_PASSWORD_MODAL_OPEN, SIGNUP_MODAL_CLOSE, SIGNUP_MODAL_OPEN, UPDATE_STUDENT_TAKEN_EXAM, USER_SIGN_OUT, } from './authContants';
import { CLEAR_POST_EXAM, GET_ALL_EXAMS, GET_ALL_EXAMS_SUCCESS, GET_ALL_EXAM_SET_DETAILS, GET_EXAM_BY_ID, GET_EXAM_BY_ID_SUCCESS, GET_QUESTIONS_BY_EXAMSET, GET_QUESTIONS_BY_EXAMSET_SUCCESS, GET_QUESTIONS_ON_PAGE_LOAD, GET_TENANT_DETAIL_BY_CODE, GET_TENANT_DETAIL_BY_CODE_FAILURE, GET_TENANT_DETAIL_BY_CODE_SUCCESS, POST_CHECK_COUPON_CODE, POST_CHECK_COUPON_CODE_FAILURE, POST_CHECK_COUPON_CODE_SUCCESS, POST_CONTACT_US_DETAIL, POST_PAGE_AUDIT, POST_PAYPAL_PAYMENT_INITIATE, POST_PURCHASE_STATUS, POST_PURCHASE_STATUS_SUCCESS, POST_SEARCH_EXAM, POST_SEARCH_EXAM_SUCCESS, SAVE_EXAM_SET_DETAILS_SUCCESS, SET_QUESTION_PREVIEW, SET_SNACKBAR, UPDATE_LOADER, } from './constants';
import { FULL_SCREEN_EXIT_COUNT, GET_EXAMS_DETAILS_ON_START, GET_STUDENT_EXAM_QUESTION_CHOICE, GET_STUDENT_EXAM_QUESTION_CHOICE_SUCCESS, GET_STUDENT_EXAM_RESULT, GET_STUDENT_EXAM_RESULT_SUCCESS, MARK_QUESTION_FOR_REVIEW, POST_BUY_EXAM, POST_BUY_EXAM_SUCCESS, POST_EXAM_AUDIT, POST_EXAM_EVERY_CHOICE_CLICK, POST_EXAM_START, POST_EXAM_START_FAILURE, POST_EXAM_START_SUCCESS, POST_EXAM_SUBMIT, UNMARK_QUESTION_FOR_REVIEW, } from './studentExamConstants';
export const getAllExams = () => ({
    type: GET_ALL_EXAMS,
});
export const getAllExamsSuccess = (payload) => ({
    type: GET_ALL_EXAMS_SUCCESS,
    payload,
});
export const getExamById = (payload) => ({
    type: GET_EXAM_BY_ID,
    payload,
});
export const getExamByIdSuccess = (payload) => ({
    type: GET_EXAM_BY_ID_SUCCESS,
    payload,
});
export const setSnackbar = (payload) => ({
    type: SET_SNACKBAR,
    payload,
});
export const getAllExamSetDetails = (payload) => ({
    type: GET_ALL_EXAM_SET_DETAILS,
    payload,
});
export const saveExamSetDetailsSuccess = (payload) => ({
    type: SAVE_EXAM_SET_DETAILS_SUCCESS,
    payload,
});
export const getQuestionsByExamSet = (payload) => ({
    type: GET_QUESTIONS_BY_EXAMSET,
    payload,
});
export const getQuestionsByExamSetSuccess = (payload) => ({
    type: GET_QUESTIONS_BY_EXAMSET_SUCCESS,
    payload,
});
export const getQuestionPageLoad = (payload) => ({
    type: GET_QUESTIONS_ON_PAGE_LOAD,
    payload,
});
export const updateLoader = (payload) => ({
    type: UPDATE_LOADER,
    payload,
});
export const setQuestionPreview = (payload) => ({
    type: SET_QUESTION_PREVIEW,
    payload,
});
export const markQuestionForReview = (payload) => ({
    type: MARK_QUESTION_FOR_REVIEW,
    payload,
});
export const unMarkQuestionForReview = (payload) => ({
    type: UNMARK_QUESTION_FOR_REVIEW,
    payload,
});
export const loginModalOpen = () => ({
    type: LOGIN_MODAL_OPEN,
});
export const loginModalClose = () => ({
    type: LOGIN_MODAL_CLOSE,
});
export const signUpModalOpen = () => ({
    type: SIGNUP_MODAL_OPEN,
});
export const signUpClose = () => ({
    type: SIGNUP_MODAL_CLOSE,
});
export const fullScreenExitCount = () => ({
    type: FULL_SCREEN_EXIT_COUNT,
});
export const userSignOut = () => ({
    type: USER_SIGN_OUT,
});
export const postStudentSignUp = (payload) => ({
    type: POST_STUDENT_SIGN_UP,
    payload,
});
export const postStudentSignUpSuccess = (payload) => ({
    type: POST_STUDENT_SIGN_UP_SUCCESS,
    payload,
});
export const postStudentSignUpFailure = (payload) => ({
    type: POST_STUDENT_SIGN_UP_FAILURE,
    payload,
});
export const postTeacherSignUp = (payload) => ({
    type: POST_TEACHER_SIGN_UP,
    payload,
});
export const postTeacherSignUpSuccess = () => ({
    type: POST_TEACHER_SIGN_UP_SUCCESS,
});
export const postStudentLogin = (payload) => ({
    type: POST_STUDENT_LOGIN,
    payload,
});
export const postTeacherLogin = (payload) => ({
    type: POST_TEACHER_LOGIN,
    payload,
});
export const postStudentPasswordReset = (payload) => ({
    type: POST_STUDENT_PASSWORD_RESET,
    payload,
});
export const postTeacherPasswordReset = (payload) => ({
    type: POST_TEACHER_PASSWORD_RESET,
    payload,
});
export const postStudentPasswordResetSuccess = () => ({
    type: POST_STUDENT_PASSWORD_RESET_SUCCESS,
});
export const postTeacherPasswordResetSuccess = () => ({
    type: POST_TEACHER_PASSWORD_RESET_SUCCESS,
});
export const resetPasswordModalOpen = () => ({
    type: RESET_PASSWORD_MODAL_OPEN,
});
export const resetPasswordModalClose = () => ({
    type: RESET_PASSWORD_MODAL_CLOSE,
});
export const postExamStart = (payload) => ({
    type: POST_EXAM_START,
    payload,
});
export const postExamStartSuccess = (payload) => ({
    type: POST_EXAM_START_SUCCESS,
    payload,
});
export const postExamStartFailure = (payload) => ({
    type: POST_EXAM_START_FAILURE,
    payload,
});
export const postExamSubmit = (payload) => ({
    type: POST_EXAM_SUBMIT,
    payload,
});
export const postExamAudit = (payload) => ({
    type: POST_EXAM_AUDIT,
    payload,
});
export const postExamEveryChoiceClick = (payload) => ({
    type: POST_EXAM_EVERY_CHOICE_CLICK,
    payload,
});
export const postStudentLoginSuccess = (payload) => ({
    type: POST_STUDENT_LOGIN_SUCCESS,
    payload,
});
export const postStudentLoginFailure = (payload) => ({
    type: POST_STUDENT_LOGIN_FAILURE,
    payload,
});
export const postTeacherLoginSuccess = (payload) => ({
    type: POST_TEACHER_LOGIN_SUCCESS,
    payload,
});
export const postTeacherLoginFailure = (payload) => ({
    type: POST_TEACHER_LOGIN_FAILURE,
    payload,
});
export const getStudentTakenExam = () => ({
    type: GET_STUDENT_TAKEN_EXAM,
});
export const getStudentTakenExamSuccess = (payload) => ({
    type: GET_STUDENT_TAKEN_EXAM_SUCCESS,
    payload,
});
export const getStudentExamResult = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT,
    payload,
});
export const getStudentExamResultSuccess = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT_SUCCESS,
    payload,
});
export const getStudentExamQuestionChoice = (payload) => ({
    type: GET_STUDENT_EXAM_QUESTION_CHOICE,
    payload,
});
export const getStudentExamQuestionChoiceSuccess = (payload) => ({
    type: GET_STUDENT_EXAM_QUESTION_CHOICE_SUCCESS,
    payload,
});
export const getExamDetailsOnExamStart = () => ({
    type: GET_EXAMS_DETAILS_ON_START,
});
export const updateStudentExams = (payload) => ({
    type: UPDATE_STUDENT_TAKEN_EXAM,
    payload,
});
export const postBuyExam = (payload) => ({
    type: POST_BUY_EXAM,
    payload,
});
export const postBuyExamSuccess = (payload) => ({
    type: POST_BUY_EXAM_SUCCESS,
    payload,
});
export const postSearchExam = (payload) => ({
    type: POST_SEARCH_EXAM,
    payload,
});
export const postSearchExamSuccess = (payload) => ({
    type: POST_SEARCH_EXAM_SUCCESS,
    payload,
});
export const clearSearchExam = () => ({
    type: CLEAR_POST_EXAM,
});
export const forgotPasswordModalOpen = () => ({
    type: FORGOT_PASSWORD_OPEN,
});
export const forgotPasswordModalClose = () => ({
    type: FORGOT_PASSWORD_CLOSE,
});
export const postTenantForgotPassword = (payload) => ({
    type: POST_TENANT_FORGOT_PASSWORD,
    payload,
});
export const postStudentForgotPassword = (payload) => ({
    type: POST_STUDENT_FORGOT_PASSWORD,
    payload,
});
export const getStudentExamResultAnalysis = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT_ANALYSIS,
    payload,
});
export const getStudentExamResultAnalysisSuccess = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS,
    payload,
});
export const getStudentExamResultAnalysisFailure = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE,
    payload,
});
export const postPurchaseStatus = (payload) => ({
    type: POST_PURCHASE_STATUS,
    payload,
});
export const postPurchaseStatusSuccess = (payload) => ({
    type: POST_PURCHASE_STATUS_SUCCESS,
    payload,
});
export const postTenantForgotPasswordVerify = (payload) => ({
    type: POST_TENANT_FORGOT_PASSWORD_VERIFY,
    payload,
});
export const postStudentForgotPasswordVerify = (payload) => ({
    type: POST_STUDENT_FORGOT_PASSWORD_VERIFY,
    payload,
});
export const postContactUsDetail = (payload) => ({
    type: POST_CONTACT_US_DETAIL,
    payload,
});
export const postPageAudit = (payload) => ({
    type: POST_PAGE_AUDIT,
    payload,
});
export const postPaypalPaymentInitiate = (payload) => ({
    type: POST_PAYPAL_PAYMENT_INITIATE,
    payload,
});
export const postCheckCouponCode = (payload) => ({
    type: POST_CHECK_COUPON_CODE,
    payload,
});
export const postCheckCouponCodeSuccess = (payload) => ({
    type: POST_CHECK_COUPON_CODE_SUCCESS,
    payload,
});
export const postCheckCouponCodeFailure = (payload) => ({
    type: POST_CHECK_COUPON_CODE_FAILURE,
    payload,
});
export const getTenantDetailByCode = (payload) => ({
    type: GET_TENANT_DETAIL_BY_CODE,
    payload,
});
export const getTenantDetailByCodeSuccess = (payload) => ({
    type: GET_TENANT_DETAIL_BY_CODE_SUCCESS,
    payload,
});
export const getTenantDetailByCodeFailure = (payload) => ({
    type: GET_TENANT_DETAIL_BY_CODE_FAILURE,
    payload,
});
